

























































































































































import { Vue, Component, Prop, Watch, Emit, Ref } from "vue-property-decorator";
import moment from "moment-jalaali";
import { AxiosResponse } from "axios";
import { UserApi } from "@/networks/UserApi";
@Component
export default class component_name extends Vue {
  @Prop({ default: "5" })
  office_id: any;
  @Prop()
  type: any;
  today: any = moment().format("YYYY/MM/DD");
  selectedTime: any = null;
  private times: Array<Record<string, string>> = [];

  setTime(time: any) {
    if (time.cnt == "false") {
      return;
    }
    this.selectedTime = time;
    this.$emit("updateTime", this.selectedTime.value);
  }
  @Watch("type")
  checkAgain() {
    this.selectedDate = null;
    this.selectedTime = null;
    this.checkAvailability();
  }
  @Watch("office_id")
  checkAgainoffice() {
    this.selectedDate = null;
    this.selectedTime = null;
    this.checkAvailability();
  }
  public async checkAvailability(): Promise<void> {
    try {
      const res: AxiosResponse = await new UserApi().checkAvailability({
        office_id:
          this.type == "phone" ||
          this.type == "expert-doctor-online" ||
          this.type == "managers-online"
            ? `5`
            : `${this.office_id}`,
        date: this.selectedDate || moment().format("YYYY/MM/DD"),
        type: this.type
      });
      const times: Record<string, string>[] = [];
      this.times.map((x) => {
        let insert = false;
        res.data.data.forEach((y: { time: string; cnt: string }) => {
          if (y.time.substr(0, 5) == x.value) {
            times.push({
              text: `ساعت ${x.value} ${y.cnt ? "" : "(ناموجود)"}`,
              value: `${x.value}`,
              cnt: `${y.cnt}`
            });
            insert = true;
          }
        });
        if (!insert) {
          times.push({
            text: `ساعت ${x.value}`,
            value: `${x.value}`,
            cnt: `true`
          });
        }
      });
      this.times = times;
      this.selectedTime = this.times.find((item) => item.cnt);
    } catch (error: any) {
      console.log(error);
    }
  }
  selectedDate: any = null;
  get isShahrivar() {
    if (this.month == "شهریور") {
      return true;
    } else {
      return false;
    }
  }
  setDate(index: any) {
    if (moment(this.today).format("jMMMM") == this.month) {
      this.selectedDate = moment(this.today)
        .add(index - 1, "day")
        .format("YYYY/MM/DD");
    } else {
      let next_month = moment(this.today).add(1, "jMonth").startOf("jMonth");
      this.selectedDate = moment(next_month)
        .add(index - 1, "day")
        .format("YYYY/MM/DD");
    }
    this.$emit("updateDate", this.selectedDate);
    this.checkAvailability();
  }
  getdaystring(index: any) {
    if (moment(this.today).format("jMMMM") == this.month) {
      return moment(this.today)
        .add(index - 1, "day")
        .format("dddd");
    } else {
      let next_month = moment(this.today).add(1, "jMonth").startOf("jMonth");
      return moment(next_month)
        .add(index - 1, "day")
        .format("dddd");
    }
  }
  get getSelectDay() {
    return moment(this.selectedDate).format("jDD");
  }
  getday(index: any) {
    if (moment(this.today).format("jMMMM") == this.month) {
      return moment(this.today)
        .add(index - 1, "day")
        .format("jDD");
    } else {
      let next_month = moment(this.today).add(1, "jMonth").startOf("jMonth");
      return moment(next_month)
        .add(index - 1, "day")
        .format("jDD");
    }
  }
  get startday(): any {
    if (moment(this.today).format("jMMMM") == this.month) {
      return moment(this.today).format("jDD");
    } else {
      return 1;
    }
  }
  get day_in_month(): any {
    if (moment(this.today).format("jMMMM") == this.month) {
      return moment.jDaysInMonth(
        Number(moment(this.today).format("jYYYY")),
        Number(moment(this.today).format("jMM") - 1)
      );
    } else {
      return moment.jDaysInMonth(
        Number(moment(this.today).add(1, "jMonth").format("jYYYY")),
        Number(moment(this.today).add(1, "jMonth").format("jMM") - 1)
      );
    }
  }
  get mounths(): any {
    return [
      moment(this.today).format("jMMMM"),
      moment(this.today).add(1, "jMonth").format("jMMMM"),
      moment(this.today).add(2, "jMonth").format("jMMMM")
    ];
  }
  month: any = this.mounths[0];
  @Watch("selectedDate")
  updateDate() {
    this.$emit("updateDate", this.selectedDate);
  }
  @Watch("selectedTime")
  updateTime() {
    this.$emit(
      "updateTime",
      this.selectedTime ? this.selectedTime.value : null
    );
  }
  mounted(): any {
    for (let i = 9; i <= 23; i++) {
      this.times.push({
        text: `ساعت ${i}:00`,
        value: `${("00" + i).substr(-2)}:00`,
        cnt: "0"
      });
    }
    this.checkAvailability();
    console.log("mounted");
    this.selectedDate = this.today;
  }
}
