























































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import store from "../../../store";
import { AxiosResponse } from "axios";
import DatetimePicker from "vue-persian-datetime-picker";
import { UserApi } from "@/networks/UserApi";
import Enum from "@/config/enum";
import Office from "@/views/components/inputs/office.vue";
import TimeBottomSheet from "@/views/components/pages/User/QuickBottomSheet.vue";

@Component({
  components: {
    DatetimePicker,
    Office: Office,
    TimeBottomSheet
  }
})
export default class Quick extends Vue {
  desktop = window.innerWidth > 600 ? true : false;
  sheet = false;
  snackbar = false;
  private types = [
    ...Enum.eventTypesQuick,
    {
      text: "مشاوره رایگان (جشنواره/جلسه دوم/قرارداد)",
      value: "free"
    }
  ];
  private pureTypes = Enum.eventTypesQuick;
  private source = Enum.userSource;
  private times: Array<Record<string, string>> = [];
  private tab = 0;
  private form = {
    fname: "",
    summery: "",
    lname: "",
    mobile: "",
    manual_payment: 0,
    type: "null",
    officeId: null,
    date: "",
    time: "",
    source: ""
  };
  private hiddenSomeFields = false;
  private hiddenOffice = false;
  private errors: Record<string, Array<string>> = {};
  private datePopup = false;

  updateTime(time: any) {
    this.form.time = time;
  }

  updateDate(date: any) {
    this.form.date = date;
  }

  changedTabs(data: any) {
    console.log("data", data);
    this.form = {
      fname: "",
      summery: "",
      lname: "",
      mobile: "",
      manual_payment: 0,
      type: "null",
      officeId: null,
      date: "",
      time: "",
      source: ""
    };
  }

  checkSheet() {
    if (this.form.type) {
      if (
        this.form.type == "phone" ||
        this.form.type == "expert-doctor-online" ||
        this.form.type == "managers-online"
      ) {
        this.sheet = !this.sheet;
      } else {
        if (this.form.officeId) {
          this.sheet = !this.sheet;
        } else {
          store.commit("showError", {
            message: "لطفا دفتر خود را انتخاب کنید",
            color: "red"
          });
        }
      }
    } else {
      store.commit("showError", {
        message: "لطفا نوع مشاوره را انتخاب کنید",
        color: "red"
      });
    }
  }

  mounted() {
    for (let i = 9; i <= 23; i++) {
      this.times.push({
        text: `ساعت ${i}:00`,
        value: `${("00" + i).substr(-2)}:00`,
        cnt: "0"
      });
    }
  }

  public async submit(): Promise<void> {
    try {
      const res: AxiosResponse = await new UserApi().quick({
        ...this.form,
        method: this.tab
      });
      (this.$refs.form as Vue & { reset: () => boolean }).reset();
      this.errors = {};
      this.form = {
        fname: "",
        summery: "",
        lname: "",
        manual_payment: 0,
        mobile: "",
        type: "",
        officeId: null,
        date: "",
        time: "",
        source: "null"
      };
      store.commit("showError", {
        message: res.data.message,
        color: "success",
        meta: {
          // user: res.data.data?.user?.id,
          // event: res.data.data?.event?.id
        }
      });
    } catch (error: any) {
      if (error.response && error.response?.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }

  public async checkAvailability(): Promise<void> {
    this.times = [];
    for (let i = 9; i <= 23; i++) {
      this.times.push({
        text: `ساعت ${i}:00`,
        value: `${("00" + i).substr(-2)}:00`,
        cnt: "0"
      });
    }
    try {
      const res: AxiosResponse = await new UserApi().checkAvailability({
        office_id:
          this.form.type == "phone" ||
          this.form.type == "expert-doctor-online" ||
          this.form.type == "managers-online"
            ? `5`
            : `${this.form.officeId}`,
        date: this.form.date,
        type: this.form.type
      });
      const times: Record<string, string>[] = [];
      this.times.map((x) => {
        let insert = false;
        res.data.data.forEach((y: { time: string; cnt: string }) => {
          if (y.time.substr(0, 5) == x.value) {
            times.push({
              text: `ساعت ${x.value} ${y.cnt ? "" : "(ناموجود)"}`,
              value: `${x.value}`,
              cnt: `${y.cnt}`
            });
            insert = true;
          }
        });
        if (!insert) {
          times.push(x);
        }
      });
      this.times = times;
    } catch (error: any) {
      console.log(error);
    }
  }

  @Watch("form.date")
  public onDateChange(val: string, oldVal: string): void {
    if (val != oldVal && val) {
      this.checkAvailability();
    }
  }

  @Watch("form.type")
  public onTypeChange(val: string, oldVal: string): void {
    this.form.date = "";
    this.form.time = "";
    if (val != oldVal) {
      this.hiddenSomeFields = !Enum.userConnectSources.includes(val);
      this.hiddenOffice = ![
        ...Enum.userConnectSources,
        ...Enum.onlineTypes
      ].includes(val);
    }
  }
}
